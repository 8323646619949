import Swiper, { EffectFade } from "swiper";
const swiperContainer = document.querySelector(".swiper");
const overlayLeft = document.querySelector(".overlay.left");
const overlayRight = document.querySelector(".overlay.right");
const body = document.body;

// Now you can use all slider methods like
const swiper = new Swiper(".swiper", {
  modules: [EffectFade],
  speed: 1000,
  loop: true,
  effect: "fade",
  fadeEffect: {
    crossFade: true,
  },
});

overlayLeft.addEventListener("click", () => swiper.slidePrev());
overlayRight.addEventListener("click", () => swiper.slideNext());

function toggleDark() {
  let has = swiper.visibleSlides[0]
    .querySelector("figure")
    .classList.contains("dark");
  if (has) body.classList.add("dark");
  else body.classList.remove("dark");
}

swiper.on("slideChange", (e) => {
  toggleDark();
});

toggleDark();

const header = document.querySelector("header");
const overlay = document.querySelector("#overlay");
function openAbout() {
  document.body.classList.add("active");
}
function closeAbout() {
  document.body.classList.remove("active");
}
header.addEventListener("click", openAbout);
overlay.addEventListener("click", closeAbout);
setTimeout(() => {
  document.body.classList.add("loaded");
}, 1000);
